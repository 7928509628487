@import url('/src/assets/font/inter.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

.rhap_container {
    background-color: transparent !important;
    /*border-width: 1px !important;*/
    /*border-radius: 0.375rem;*/
    /*border-color: #121821 !important;*/
}

.rhap_time {
    color: white !important;
}

.rhap_main-controls-button {
    color: white !important;
}

.rhap_volume-button {
    color: white !important;
}

.rhap_volume-indicator {
    background-color: white !important;
}

.rhap_repeat-button {
    color: white !important;
}

.rhap_progress-filled {
    background-color: white !important;
}

.rhap_download-progress {
    background-color: #c2c2c2 !important;
}

.rhap_progress-indicator {
    background: white !important;
}